import { useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { IconButton, Box, Button } from '@mui/material';
import { Close as CloseIcon, InfoOutlined } from '@mui/icons-material';

import './Error.css';

export default function Error({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  console.log(error);

  function CloseComponent() {
    return (
      <IconButton className="error-dialog-close" aria-label="close" onClick={() => setVisible(false)}>
        <CloseIcon />
      </IconButton>
    );
  }

  return visible ? (
    <Box className="error-dialog">
      <div className="error-dialog-row">
        <div className="error-dialog-icon">
          <InfoOutlined />
        </div>
        <div className="error-dialog-text">{t('error.dialog.unknownErrorMessage')}</div>
        <div className="error-dialog-icon">
          <CloseComponent />
        </div>
      </div>
      <Button className="error-dialog-button" type="button" onClick={resetErrorBoundary}>
        {t('error.dialog.tryAgain')}
      </Button>
    </Box>
  ) : null;
}
