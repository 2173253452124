import { Snackbar } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import Grow, { GrowProps } from '@mui/material/Grow';
import { useRef, useState, useEffect } from 'react';

export default function Notification({
  close,
  message,
  severity,
}: {
  close: (message: string) => void;
  message: string;
  severity: AlertColor;
}) {
  const masterMessageRef = useRef<HTMLDivElement | null>(null);
  const [masterWidth, setMasterWidth] = useState<number | null>(null);

  useEffect(() => {
    if (masterMessageRef.current) {
      setMasterWidth(masterMessageRef.current.offsetWidth);
    }
  }, [message]);

  function handleClose() {
    close(message);
  }

  function GrowTransition(props: GrowProps) {
    return <Grow {...props} />;
  }

  function getMessage(message: string) {
    const lines = message.split('\n');
    if (lines.length == 1) {
      return message;
    }
    return (
      <>
        <div
          ref={masterMessageRef}
          style={{
            fontWeight: 'bold',
            display: 'inline-block',
          }}
        >
          {lines[0]}
        </div>

        {lines.slice(1).map((line, index) => (
          <div
            key={index}
            style={{
              fontWeight: 'normal',
              fontSize: '0.7rem',
              wordWrap: 'break-word',
              maxWidth: masterWidth ? `${masterWidth}px` : '100%',
              minWidth: `400px`,
            }}
          >
            {line}
          </div>
        ))}
      </>
    );
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={GrowTransition}
      open={true}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%', '& .MuiAlert-message': { whiteSpace: 'pre-line' } }}
      >
        {getMessage(message)}
      </Alert>
    </Snackbar>
  );
}
