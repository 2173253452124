import { ReactElement, RefObject } from 'react';
import { TargetDescription, User } from './tmp';
import { UserInITrack } from '@/generated/model/userInITrack';

export type PopupType = {
  title: string;
  icon: ReactElement;
  element: ReactElement | undefined;
  ref: RefObject<HTMLAnchorElement | null> | undefined;
  showToken: boolean;
  mainFunction: boolean;
};

// null: nincs bejelentkezve, undefined: nem tudjuk
export type UserState = User | null | undefined | 'token_failure';

export type UserMessage = {
  message: string | undefined;
  date: number;
};

export enum TabValue {
  targetSelect,
  dateSelect,
  result,
}

export type SelectedRoute = {
  id: number;
  target: TargetDescription;
  fromTimeMicros: number;
  toTimeMicros: number;
};

export type Mode = 'target' | 'route';

export type ThreadUserThreadId = {
  user: UserInITrack;
  threadId: number;
};

export type LoginAutocompleteData = {
  username: string;
  password: string;
};
