import { ErrorInfo, RefObject, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient } from '@tanstack/react-query';

import {
  Message,
  SyncAlt,
  LocalGasStation,
  Logout,
  GpsFixed,
  ContactPhone,
  Person2,
  PermDeviceInformation,
  MoreHoriz,
} from '@mui/icons-material';
import { Badge } from '@mui/material';

import { PopupType } from './types';
import type {
  ContactsPopup,
  MessagesPopup,
  More,
  TrackingPopup,
  RoutesPopup,
  RefuelsPopup,
  ProfilePopup,
  SysinfoPopup,
} from './components';
import Error from '@/components/common/Error';

// eslint-disable-next-line react-refresh/only-export-components
const TrackingPopupLazy = lazy(() => import('@/components/tracking/TrackingPopup')) as typeof TrackingPopup;
// eslint-disable-next-line react-refresh/only-export-components
const RoutesPopupLazy = lazy(() => import('@/components/routes/RoutesPopup')) as typeof RoutesPopup;
// eslint-disable-next-line react-refresh/only-export-components
const MessagesPopupLazy = lazy(() => import('@/components/messages/MessagesPopup')) as typeof MessagesPopup;
// eslint-disable-next-line react-refresh/only-export-components
const RefuelsPopupLazy = lazy(() => import('@/components/refuels/RefuelsPopup')) as typeof RefuelsPopup;
// eslint-disable-next-line
const MoreLazy = lazy(() => import('@/components/More')) as typeof More;
// eslint-disable-next-line react-refresh/only-export-components
const ContactsPopupLazy = lazy(() => import('@/components/contacts/ContactsPopup')) as typeof ContactsPopup;
// eslint-disable-next-line react-refresh/only-export-components
const ProfilePopupLazy = lazy(() => import('@/components/profile/ProfilePopup')) as typeof ProfilePopup;
// eslint-disable-next-line react-refresh/only-export-components
const SysinfoPopupLazy = lazy(() => import('@/components/sysinfo/SysinfoPopup')) as typeof SysinfoPopup;

const newMessageCount = 0;

export function popupDefs({
  close,
  logout,
  ref,
  client,
  isBottom,
}: {
  close: () => void;
  logout: () => void;
  ref: RefObject<HTMLAnchorElement | null>;
  client: QueryClient;
  isBottom: boolean;
}): PopupType[] {
  function logError(error: Error, info: ErrorInfo) {
    console.log(error.message);
    console.log(info);
  }

  // FIXME ez igy nem lazy loading ?
  const arr: PopupType[] = [
    {
      title: 'mainMenu.tracking',
      icon: <GpsFixed fontSize={isBottom ? 'small' : 'medium'} />,
      element: <TrackingPopupLazy close={close} data-qa-element-id="tracking-popup" />,
      ref: undefined,
      showToken: true,
      mainFunction: true,
    },
    {
      title: 'mainMenu.routes',
      icon: <SyncAlt fontSize={isBottom ? 'small' : 'medium'} />,
      element: (
        <ErrorBoundary FallbackComponent={Error} onError={logError} onReset={() => client.clear()}>
          <RoutesPopupLazy close={close} data-qa-element-id="routes-popup" />
        </ErrorBoundary>
      ),
      ref: undefined,
      showToken: false,
      mainFunction: true,
    },
    {
      title: 'mainMenu.messages',
      icon:
        newMessageCount > 0 ? (
          <Badge color="secondary" variant="dot">
            <Message fontSize={isBottom ? 'small' : 'medium'} />
          </Badge>
        ) : (
          <Message fontSize={isBottom ? 'small' : 'medium'} />
        ),
      // FIXME ne a react=query teljes cache-t torolje ? csak a messages ?
      element: (
        <ErrorBoundary FallbackComponent={Error} onError={logError} onReset={() => client.clear()}>
          <MessagesPopupLazy close={close} data-qa-element-id="messages-popup" />
        </ErrorBoundary>
      ),
      ref: undefined,
      showToken: false,
      mainFunction: true,
    },
    {
      title: 'mainMenu.refuels',
      icon: <LocalGasStation fontSize={isBottom ? 'small' : 'medium'} />,
      element: <RefuelsPopupLazy close={close} data-qa-element-id="refuels-popup" />,
      ref: undefined,
      showToken: false,
      mainFunction: true,
    },
  ];

  let additionalElement: PopupType[];
  if (isBottom) {
    additionalElement = [
      {
        title: 'mainMenu.more',
        icon: <MoreHoriz fontSize="small" />,
        element: <MoreLazy close={close} ref={ref} logout={logout} data-qa-element-id="more-menu-list" />,
        ref,
        showToken: true,
        mainFunction: false,
      },
    ];
  } else {
    additionalElement = [
      {
        title: 'mainMenu.contacts',
        icon: <ContactPhone fontSize="medium" />,
        element: <ContactsPopupLazy close={close} />,
        ref: undefined,
        showToken: true,
        mainFunction: false,
      },
      {
        title: 'mainMenu.profile',
        icon: <Person2 fontSize="medium" />,
        element: <ProfilePopupLazy close={close} />,
        ref: undefined,
        showToken: false,
        mainFunction: false,
      },
      {
        title: 'mainMenu.systemInformation',
        icon: <PermDeviceInformation fontSize="medium" />,
        element: <SysinfoPopupLazy close={close} />,
        ref: undefined,
        showToken: true,
        mainFunction: false,
      },
      {
        title: 'mainMenu.logout',
        icon: <Logout fontSize="medium" />,
        element: undefined,
        ref: undefined,
        showToken: true,
        mainFunction: false,
      },
    ];
  }
  return arr.concat(additionalElement);
}
