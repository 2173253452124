import { useContext, useState, useRef, useMemo, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';

import { PopupType } from '@/types';
import { popupDefs } from '@/popups';
import { ctxPopup, PopupContext } from '@/contexts/PopupContext';
import { ctxUser } from '@/contexts/UserContext';

import './Home.css';

export function HomeBottomNav({ logout }: { logout: () => void }) {
  const [value, setValue] = useState(-1);
  const popupCtx = useContext(ctxPopup);
  const userCtx = useContext(ctxUser);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { t } = useTranslation();
  const client = useQueryClient();

  const popups: PopupType[] = useMemo(() => {
    let popups = popupDefs({
      close: () => {
        setValue(-1);
        popupCtx?.setPopupMain(undefined);
      },
      logout,
      ref,
      client,
      isBottom: true,
    });
    if (userCtx?.isToken()) {
      popups = popups.filter((p) => p.showToken);
    }
    return popups;
  }, [logout, popupCtx, userCtx, client]);
  const narrowScreenSize = useMediaQuery('(max-width: 480px)');

  if (!popupCtx) return;

  return (
    <Box className="home-nav">
      <Container maxWidth={false} className="home-bottom-nav-container">
        <BottomNavigation
          showLabels
          value={value}
          className="home-bottom-nav-navigator"
          onChange={(_event: SyntheticEvent, newValue: number) => {
            popupCtx.setPopupMain(popups[newValue].element);
            setValue(newValue);
          }}
        >
          {popups.map((p) =>
            p.ref ? (
              <BottomNavigationAction
                href="#"
                ref={p.ref}
                key={p.title}
                label={narrowScreenSize ? '' : t(p.title)}
                icon={p.icon}
                data-qa-element-id={`bottom-navigation-action-${p.title}`}
                className="home-bottom-nav-action"
              />
            ) : (
              <BottomNavigationAction
                key={p.title}
                label={narrowScreenSize ? '' : t(p.title)}
                icon={p.icon}
                data-qa-element-id={`bottom-navigation-action-${p.title}`}
                className="home-bottom-nav-action"
              />
            )
          )}
        </BottomNavigation>
      </Container>
    </Box>
  );
}

export function HomeLeftNav({ logout }: { logout: () => void }) {
  const popupCtx = useContext(ctxPopup);
  const userCtx = useContext(ctxUser);
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { t } = useTranslation();
  const client = useQueryClient();
  const [open, setOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem('drawerState');
    return savedState ? JSON.parse(savedState) : false;
  });
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    localStorage.setItem('drawerState', JSON.stringify(open));
  }, [open]);

  const popups: PopupType[] = useMemo(() => {
    let popups = popupDefs({
      close: () => {
        popupCtx?.setPopupMain(undefined);
        setSelected(-1);
      },
      logout,
      ref,
      client,
      isBottom: false,
    });
    if (userCtx?.isToken()) {
      popups = popups.filter((p) => p.showToken);
    }
    return popups;
  }, [logout, popupCtx, userCtx, client]);

  if (!popupCtx) return;

  const mainPopups = popups.filter((p) => p.mainFunction);
  const secondaryPopups = popups.filter((p) => !p.mainFunction);

  function createIconList(popups: PopupType[], popupCtx: PopupContext, offset: number) {
    return (
      <>
        <List>
          {popups.map((p, i) => (
            <ListItem key={p.title}>
              <ListItemButton
                className={`home-left-nav-list-button${selected == i + offset ? ' Mui-selected' : ''}`}
                onClick={
                  p.element !== undefined
                    ? () => {
                        popupCtx.setPopupMain(p.element);
                        setSelected(i + offset);
                      }
                    : () => logout()
                }
              >
                <ListItemIcon className={open ? undefined : 'home-left-nav-open-list'}>{p.icon}</ListItemIcon>
                {open && <ListItemText primary={t(p.title)} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  return (
    <Box className={`home-nav ${open ? 'home-left-nav-opened' : 'home-left-nav-closed'}`}>
      <Drawer
        variant="permanent"
        className={`home-left-nav-navigator ${open ? 'home-left-nav-opened' : 'home-left-nav-closed'}`}
        classes={{ paper: open ? 'home-left-nav-opened' : 'home-left-nav-closed' }}
      >
        <div className="home-left-nav-list">
          {createIconList(mainPopups, popupCtx, 0)}
          <div className="home-left-nav-secondary-list">
            {createIconList(secondaryPopups, popupCtx, mainPopups.length)}
          </div>
        </div>
        <div className="home-left-nav-list-close-container">
          <Button onClick={() => setOpen(!open)} className="home-left-nav-list-close">
            {open ? <KeyboardDoubleArrowLeft fontSize="medium" /> : <KeyboardDoubleArrowRight fontSize="medium" />}
          </Button>
        </div>
      </Drawer>
    </Box>
  );
}
